@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  max-width: 1200px;
}

@layer utilities {
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* Number of lines to show */
  }
}

@import url("https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Cormorant:ital,wght@0,300..700;1,300..700&family=Inter:wght@100..900&display=swap");

@font-face {
  font-family: "AhkioBold";
  src: url("./Assets/Fonts/Ahkio_Headlines/Ahkio-W00-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Univers";
  src: url("./Assets/Fonts/Text/UniversNextProRegular.ttf")
    format("truetype");
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "AhkioBold", sans-serif;
  letter-spacing: 0.1rem;
}

body {
  font-family: "Univers", sans-serif;
}
