.tarifCompareGroup__head-item[data-index="0"] {
  background-color: #f07814;
  order: 0;
}
@media (min-width: 992px) {
  .tarifCompareGroup__head-item {
    height: 148px;
  }
}

.tarifCompareGroup__head-item {
  flex: 1 1 10px;
  color: #fff;
  background-color: #253746;
  border-radius: 15px 3px 5px 5px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

/* This is your base styles for larger screens */

/* Regular table styles go here */

/* Responsive table styles */
.width-th {
  width: 39%;
}

.width-td {
  width: 30.5%;
}

@media screen and (max-width: 768px) {
  /* Style the table and table headers as cards */
  thead {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  thead th {
    width: max-content;
  }

  /* Style the table rows and cells */
  tbody tr {
    border: none;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  /* tbody td,
  tbody th {
    display: block;
    text-align: left;
    position: relative;
    padding-left: 40%;
    margin-bottom: 0.5em;
  } */

  tbody th {
    width: 100%;
  }

  /* Hide the first empty th */
  thead th:first-child {
    display: none;
  }

  /* Optional: Add checkmarks and other styling as needed */
}
