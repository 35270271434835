.clipPath {
    background: url("../../Assets/slideImg1.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
  }
  
  .clipPath::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.5) 90%
    );
    opacity: 0.5;
    pointer-events: none; /* Ensure the overlay doesn't interfere with interactions */
  }
  
  .card-bottom {
    clip-path: polygon(100% 0, 100% 55%, 50% 100%, 0 55%, 0 0);
  }
  